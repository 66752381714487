$(function () {

    //adding in the healthwise stylesheet after healthwise's stylesheet so that we don't need to place importants on the styles.
	$('head').append('<link rel="stylesheet" href="/css/modules/healthwise.css" type="text/css" />');

    DoctorInfiniteScroll();

    var counter = 0,
        letterDescriptions = [$('#CareText li:nth-child(1)').text(), $('#CareText li:nth-child(2)').text(), $('#CareText li:nth-child(3)').text(), $('#CareText li:nth-child(4)').text()];
    $('#LetterDescription').text(letterDescriptions[0]);

    function careRotate() {
        'use strict';

        if (counter === letterDescriptions.length) {
            counter = 0;
        }

        $('#LetterDescription').fadeIn();
        $('#LetterDescription').text(letterDescriptions[counter]);

        counter = counter + 1;
    }

    var timer = setInterval(careRotate, 2000);

    $('#Letters li').hover(function () {

        counter = $(this).index();
        $('#LetterDescription').text(letterDescriptions[counter]);
        clearInterval(timer);
    }, function () {

        timer = setInterval(careRotate, 2000);
    });

    PopulateSharePanel();
    BasicSearch();
    LightBox();
    sbg();

    if ($("#Breadcrumbs").length) {
        $('#Breadcrumbs p').html(
            $('#Breadcrumbs p').html().replace(/\&gt;/g, '<span class="fa fa-caret-right"></span>')
        );
    }

    $("#LocationsDetail .ContactWrapper .ContactCol2 p").each(function () {
        if (isEmpty($(this))) {
            $(this).remove();
        }
    });

    $(".Message").each(function () {
        if (isEmpty($(this))) {
            $(this).remove();
        }
    });
    $(".SortContainer").each(function () {
        if (isEmpty($(this))) {
            $(this).remove();
        }
    });
    $("ul").each(function () {
        if (isEmpty($(this))) {
            $(this).remove();
        }
    });

    // Locations Module -  Move Phone number into it's own div
    $('.LocationsList > ul > li').each(function () {
        $(this).addClass('ClearFix');
        var Phone = $(this).find('.Phone').addClass('Gotcha');
        $(this).find('.Headline').after('<div class="MovedPhone">' + Phone.html() + '</div>');
        Phone.remove();
        $(this).find('.MajorDetails').after($(this).find('.MinorDetails'));
        $(this).find('.Headline,.MovedPhone').wrapAll('<div class="NamePhone" />');
        $(this).find('.MajorDetails,.MinorDetails').wrapAll('<div class="Details" />');
    });

    // Now find the the locations with no phone # displaying null and replace it with blank text
    $('.MovedPhone').each(function () {
        var text = $(this).text();
        $(this).text(text.replace('null', ''));
    });

    // SET CURRENT YEAR IN FOOTER
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    $("#FooterBottom span.current-year").text(currentYear);

    truncateBoxDay();

    // INITIALIZE FANCYBOX FOR YOUTUBE
    if ($("a.YouTubeVideo").length) {
        $("a.YouTubeVideo").modaal({
            'type': 'video'
        });
    }

    $('<span class="YouTubeVideoButton" />').insertAfter('a.YouTubeVideo img');

    $("a#ProximityToggle").click(function () {
        $("#ProximityNavWrap1").toggle();
        $("#ProximityToggle").toggleClass("Open");
        return false;
    });

    $("#MobileMenuToggle a").toggle(function () {
        $(this).html("<span class='fa fa-close fa-2x'></span>");
        $("#MobileMenu").slideDown();
    }, function () {
        $(this).html("<span class='fa fa-bars fa-2x'></span>");
        $("#MobileMenu").slideUp();
    });

    /*$("#MobileSearchBar input.Button").replaceWith('<button type="submit"><span class="fa fa-search">&nbsp;&nbsp;Search</span></button>');
    $("#Header input.Button").replaceWith('<button type="submit"><span class="fa fa-search">&nbsp;&nbsp;Search</span></button>');*/

    $("#PanelSmartPanelRelatedPhysicians .NameComma").remove();

    $('#PanelSmartPanelRelatedPhysicians .NameTitleExtension').each(function () {
        $(this).parent().after(this);
    });

    if ($("#PanelSmartPanelRelatedNews ul.SPList li").length === 1) {
        $(this).addClass("Last");
    }

    //HACK LIST BEGIN

	$('#LocationTemplate .WildcardWrap p').each(function(){
		if($(this).hasClass('MoreLink')){
			$(this).removeClass('MoreLink');
			$(this).addClass('more-link');
			if($(this).children('a').text() !== 'View More'){
				$(this).children('a').text('View More');
			}
		}
	});
    /* DOCTOR SEARCH #655244
    ----------------------------------------------------------------- */
    $('.DrSearch .Gender').insertAfter('.DrSearch .PhysicianLast');

    // Aria Fixes from new default templates
    //Unwrap the fieldset from the site search.
    $('#Header #Search fieldset input').unwrap();
    
    if(location.pathname === "/") {
        //we are on the site root. Add aria to logo
        $('#Logo a').attr('aria-current', 'page');
    }
    
    $('#MainNav li.Active strong').attr('aria-current', 'page');
    //$('#ProximityNav li.Active strong').attr('aria-current', 'page');
    
    $('#ServicesSearch .AlphabetLinksInner li.Active a').attr('aria-current', 'true');
    $('#ServicesSearch .AlphabetLinksInner li.NoItems').each(function(){
        $(this).find('a').attr('aria-disabled', 'true');
    });
    

    //Doctor Profile No Tabs Hack
    if (window.location.href.indexOf("/doctors/profile/") > -1) {
        var Profile = $('.TabContainer .Tabnav li:nth-child(1)').text();
        $('.TabContainer .Tabnav').remove()
        $('.TabContainer .Gender').prepend('<h3 class="heading">' + Profile + '</h3>');
        $('.TabContainer .Tab').removeClass('Tabhide');
    }

    if ($('link[rel="shortcut icon"]').attr('href') === '/favicon.ico') {
        if (window.location.href.indexOf("/orthopedic-institute/") > -1) {
            $('link[rel="shortcut icon"]').attr('href', '/imgs/favicons/TOI.ico');
            $('link[rel="apple-touch-icon"]').attr('href', '/touch-icons/TOI/apple-touch-icon.png');
        } else if (window.location.href.indexOf("/highland-community-hospital/") > -1) {
            $('link[rel="shortcut icon"]').attr('href', '/imgs/favicons/Highland.ico');
            $('link[rel="apple-touch-icon"]').attr('href', '/touch-icons/Highland/apple-touch-icon.png');
        } else if (window.location.href.indexOf("/forrest-general-hospital/") > -1) {
            $('link[rel="shortcut icon"]').attr('href', '/imgs/favicons/FGH.ico');
            $('link[rel="apple-touch-icon"]').attr('href', '/touch-icons/FGH/apple-touch-icon.png');
        } else {
            $('link[rel="apple-touch-icon"]').attr('href', '/touch-icons/Forrest/apple-touch-icon.png');
        }
    }

    if ($('#LocationTemplate #HomeServices .cmspanel').length === 1) {
        if ($('#ServicesDescription .cmspanel').length === 0) {
            $('#ServicesDescription').hide();
            $('#ServicesLinks').addClass('col-md-offset-4');
        } else if ($('#ServicesLinks').length === 0) {
            $('#ServicesDescription').removeClass('col-md-6');
//            $('#HomeServices').css('text-align', 'center');
        }
    }


    /* Adding Services to Care and Treatment page
	----------------------------------------------------------------- */
	if (($('#ServicesSearch').not('.ModuleSearchResults')).length) {
		$('<div class="ServiceLineListCont"><p class="TextAlignCenter"><img class="Center" src="/imgs/ajax-loader.gif" alt="Loading Services" /><br />Loading Services List...</p></div>').insertAfter('#ServicesSearch .SearchBox');
		$.getFacetsAndObjectCountHtml({
			ObjectTypeCode: 17,
			OutputFacetIds: 'ce9349e1-1bda-4c59-af2f-e2dedcc42790',
			RelativeViewPath: 'Views/ServiceLines/serviceline-object-count.cshtml'
		}, function (data) {
			$('table.Paging').hide();
			$('.ServiceLineListCont').html(data);
		});
	}

  /* Show Alert Panel if Cookie 'AlertPanel' does not exist yet
    ----------------------------------------------------------------- */
    if (!$.cookie('AlertPanel')) {
        $('#AlertPanel .cmspanel').show().addClass('show');
    } else {
        $('#AlertPanel .cmspanel').hide().addClass('hide');
    }

    /* 'Add-Alert-Close-Button' Option for Alert Panel
    ----------------------------------------------------------------- */
    if ($('#AlertPanel .cmspanel .Add-Alert-Close-Button').length) {
        $('#AlertPanel .cmspanel .BasicPanel').append('<button type="button" class="closebtn"><span class="close-icon"></span><span class="sr-only">Close Alert for Session Duration</span></button>');

        $('#AlertPanel .cmspanel').on('click', 'button.closebtn', function() {
            // Set the opacity of div to 0 (transparent)
            $('#AlertPanel .cmspanel').css('opacity','0');
            
            // Hide the div after 600ms (the same amount of milliseconds it takes to fade out)
            setTimeout(function(){ $('#AlertPanel .cmspanel').css('display','none'); }, 600);
            $.cookie("AlertPanel", "closed", { path: '/' });
        });
    }



    // Forces the first "Tab" to be open on mobile sizes when the TabsToAccordions funciton is used
    //$('#ServicesSearch .TabContainer').data('accordionClasses', 'StartOn1');

    TabsToAccordions();
    isSpirit();

    //HACK LIST END
});

function isSpirit() {
    'use strict';
    var url = window.location.href;
    if(url.indexOf('/spirit-of-women/') > 0) {
        $('body').addClass('isSOW');
    }
}


function FaceBookMeta() {

    var fbimage = $('img.FacebookShare').attr('src');

    var image = (fbimage.toLowerCase().indexOf("http") >= 0 && fbimage === '' || fbimage === null) ? '' : fbimage,
        title = ($(document).attr('title') === '' || $(document).attr('title') === null) ? $('h1').text() : $(document).attr('title'),
        url = (window.location.href === '' || window.location.href === null) ? '' : window.location.href,
        desc = ($('meta[name="description"]').attr('content') === '' || $('meta[name="description"]').attr('content') === null) ? $('h1').text() : $('meta[name="description"]').attr('content');

    $("meta[property='og\\:image']").attr("content", image);
    $("meta[property='og\\:title']").attr("content", title);
    $("meta[property='og\\:url']").attr("content", url);
    $("meta[property='og\\:description']").attr("content", desc);

}

function DoctorInfiniteScroll() {

    //Dr Search Results Infinite Scroll
    if ($('#DrResults .SortContainer').length > 0) {

        $.getScript('/scripts/infiniteScroll/jquery-ias.js', function (data, textStatus, jqxhr) {

            var totalRecords = $('#DrResults .SortContainer>span').html().match(/\d+$/),
                page = 1,
                loadedPage = 0;
            var count = 10;
            if (count > totalRecords) {
                //show the correct total number of records if less than 10
                count = totalRecords;
            }
            $('#DrResults .SortContainer>span').addClass('ResultsCount');
            updateProfileCounter(count, totalRecords);
            $('#DrResults .SortContainer').clone().insertAfter('#DrResults .DrListContainer');

            jQuery.ias({
                container: '.DrList',
                item: '.Profile',
                pagination: '.Paging',
                next: '.Paging .Next',
                loader: '<img src="/scripts/infiniteScroll/images/loader.gif"/>',
                noneleft: 'End of Results',
                triggerPageThreshold: totalRecords,
                thresholdMargin: -500,
                onPageChange: function (pageNum, pageUrl, scrollOffset) {
                    // this occurs during the scroll process even when you scroll back up the page
                    //console.log(pageNum, pageUrl, scrollOffset);
                },
                onLoadItems: function () {
                    loadedPage += 1;
                    if (((page + loadedPage) * 10) <= totalRecords) {
                        console.log('hello world');
                        $('#DrResults .SortContainer .HowMany').text((page + loadedPage) * 10);
                        //console.log(loadedPage);
                        var loadedPageString = loadedPage.toString();
                        if (!(typeof _gaq === "undefined")) {
                            _gaq.push(['_trackEvent', 'Infinite Scroll', 'Scroll', 'Page ' + loadedPageString]);
                        }

                    } else {
                        //show the correct results that are displaying if not an exact multiple of 10
                        updateProfileCounter(totalRecords, totalRecords);
                    }
                }
            });
        });
    } // end ( $('#DrResults').size()>0 )

}

function updateProfileCounter(count, totalRecords) {
    $('#DrResults .SortContainer span.ResultsCount').html('<span class="Showing">Showing 1-</span><span class="HowMany">' + count + '</span><span class="OfText">&nbsp;of&nbsp;</span><span class="TotalCount">' + totalRecords + '</span>');
}

function updateLocationsCounter(count, totalRecords) {
    $('#LocationsSearch .ResultsCount').html('<span class="Showing">Showing 1-</span><span class="HowMany">' + count + '</span><span class="OfText">&nbsp;of&nbsp;</span><span class="TotalCount">' + totalRecords + '</span>');
}


function truncateBoxDay() {
    $('.BoxDay span:contains("Sunday")').text("Sun");
    $('.BoxDay span:contains("Monday")').text("Mon");
    $('.BoxDay span:contains("Tuesday")').text("Tue");
    $('.BoxDay span:contains("Wednesday")').text("Wed");
    $('.BoxDay span:contains("Thursday")').text("Thur");
    $('.BoxDay span:contains("Friday")').text("Fri");
    $('.BoxDay span:contains("Saturday")').text("Sat");
}

function isEmpty(el) {
    return !$.trim(el.html())
}

function LightBox() {
    $('a.LightBox').modaal({
        type: 'iframe',
        width: 600,
        height: 500
    });
}

// Change Tabs into Accordions
function TabsToAccordions() {

    // do this for each tab container
    $('.TabContainer').each(function (k, TabContainer) {

        // new accordion container (jquery object)
        var NewAccordion = $('<div class="ResponsiveAccordion Accordion ' + $(this).data('accordionClasses') + '"></div>');

        // loop through all tabs in the current tab container
        $(this).find('.Tab').each(function (k, tab) {

            // create objects for the new trigger and payload
            var AccordionTrigger = $('<h3>' + $(tab).find(':header:first').text() + '</h3>'),
                AccordionPayload = $('<div class="TheTabContent"></div>');

            // add trigger and empty payload to the accordion container
            AccordionTrigger.appendTo(NewAccordion);
            AccordionPayload.appendTo(NewAccordion);

            // when clicking on an accordion trigger, get the contents from the tab and put it in the payload... only if it's empty
            AccordionTrigger.mousedown(function (e) {
                if (AccordionPayload.is(':empty')) {
                    var NewTabContents = $(tab).clone();

                    // if there's a google map in the tab, then do some funky stuff
                    if (NewTabContents.find('.MapContainer').length > 0) {
                        $(tab).find('#MapId').attr('id', 'MapIdOriginal');
                        $(tab).find('.MapContainer').attr('class', 'MapContainerOriginal');
                        NewTabContents.find('#MapId').remove();
                        NewTabContents.find('.GoogleLocateOnMapLink').remove();
                        setTimeout(function () {
                            Geo_InitMap()
                        }, 1000);
                    }

                    // put tab contents in the payload
                    NewTabContents.appendTo(AccordionPayload);

                }
            }); // end click event

        });
        $(TabContainer).after(NewAccordion);
    });
    doAccordions();

    // if you addded a 'StartOn' class to make an accordion payload automatically visible, trigger it so it has content
    $('.ResponsiveAccordion[class*="StartOn"]').each(function () {
        $(this).find('.Trigger').eq(parseInt($(this).attr('class').split('StartOn')[1]) - 1).trigger('mousedown');
    });

    $('#RightColumnPanels .cmspanel').prepend('<img class="ColorBar" src="/imgs/color-bar.png" />');
    $('#LeftColumnPanels .cmspanel').prepend('<img class="ColorBar" src="/imgs/color-bar.png" />');
}

function BasicSearch() {
    $('.basicSearch').each(function () {
        var $BS = $(this),
            defaultText = $BS.find('input.thisInputText').val(),
            $input = $BS.find('input.theKeyword'),
            searchPage = $.url.attr('protocol') + '://' + $.url.attr('host') + $BS.find('input.theSearchPage').val() + '?',
            querystr = $BS.find('input.querystr').val() || '',
            querystr = (querystr.length) ? querystr + '=' : 'keyword=',
            sorting = $BS.find('input.sorting').val() || '',
            sorting = sorting !== '0' ? (sorting.length ? 'sort=' + sorting + '&' : 'sort=7&') : '',
            errorText = $BS.find('input.errorText').val() || '',
            errorText = (errorText.length) ? errorText : 'Please enter in your search criteria.';
        if (defaultText.length) {
            $input.val(defaultText)
        };
        $input
            .on('focus', function () {
                if ($(this).val() == defaultText) {
                    $(this).val('')
                }
            })
            .on('blur', function () {
                if ($(this).val() == '') {
                    $(this).val(defaultText)
                }
            })
            .on('keypress', function (e) {
                $BS.find('.error').slideUp().remove();
                if (e.keyCode == 13) {
                    e.preventDefault();
                    if (defaultText.length == 0) {
                        defaultText = ''
                    };
                    var kword = $(this).val();
                    if (kword.length && kword !== defaultText) {
                        window.location = (searchPage + sorting + querystr + kword)
                    } else {
                        showBSError($input)
                    }
                }
            });
        $BS.find('.basicSearchButton').click(function () {
            if (defaultText.length == 0) {
                defaultText = ''
            };
            var kword = $input.val();
            if (kword.length && kword !== defaultText) {
                window.location = (searchPage + sorting + querystr + kword)
            } else {
                showBSError($input)
            }
        });

        function showBSError(input) {
            if ($BS.find('.error').length == 0) {
                input.before('<div style="color:red" class="error">' + errorText + '</div>')
            }

        }
    });
}

function sbg() {
    var buttons = $('.sbg-button');

    for (var i = 0; i < buttons.length; i++) {
        var button = buttons[i]

        if (button.hasAttribute('data-sbg-isBinded')) {
            continue
        }
        button.setAttribute('data-sbg-isBinded', 'true')

        var network = button.getAttribute('data-sbg-network')

        bindButton(network, button)
    }

    function bindButton(network, button) {
        var height = button.getAttribute('data-sbg-height'),
            width = button.getAttribute('data-sbg-width'),
            top = Math.max(0, (screen.height - height) / 2),
            left = Math.max(0, (screen.width - width) / 2),
            specs =
            'height=' + height + ',width=' + width + ',top=' + top + ',left=' + left + ',status=0,toolbar=0,directories=0,location=0' + ',menubar=0,resizable=1,scrollbars=1',
            windowName = 'sbg-window-' + Math.random()

        switch (network) {
            case 'facebook':
                var url = buildUrl('http://www.facebook.com/sharer.php', {
                    's': 100,
                    'p[url]': button.getAttribute('data-sbg-url'),
                    'p[title]': button.getAttribute('data-sbg-title'),
                    'p[summary]': button.getAttribute('data-sbg-summary'),
                    'p[images][0]': button.getAttribute('data-sbg-image')
                })
                button.onclick = function () {
                    window.open(url, windowName, specs);
                }
                break;
            case 'twitter':
                var url = buildUrl('http://twitter.com/intent/tweet', {
                    'text': button.getAttribute('data-sbg-text'),
                    'via': button.getAttribute('data-sbg-via'),
                    'hashtags': button.getAttribute('data-sbg-hashtags')
                })
                button.onclick = function () {
                    window.open(url, windowName, specs);
                }
                break;
            case 'linkedin':
                var url = buildUrl('http://www.linkedin.com/shareArticle', {
                    'mini': 'true',
                    'url': button.getAttribute('data-sbg-url'),
                    'title': button.getAttribute('data-sbg-title'),
                    'source': button.getAttribute('data-sbg-source'),
                    'summary': button.getAttribute('data-sbg-summary')
                })
                button.onclick = function () {
                    window.open(url, windowName, specs);
                }
                break;
            case 'google-plus':
                var url = buildUrl(' https://plus.google.com/share', {
                    'url': button.getAttribute('data-sbg-url')
                })
                button.onclick = function () {
                    window.open(url, windowName, specs);
                }
                break;
            case 'pinterest':
                var url = buildUrl('http://www.pinterest.com/pin/create/button/', {
                    'url': button.getAttribute('data-sbg-url'),
                    'media': button.getAttribute('data-sbg-media'),
                    'description': button.getAttribute('data-sbg-description')
                })
                button.onclick = function () {
                    window.open(url, windowName, specs);
                }
                break;
            case 'email':
                var url = buildUrl('mailto:', {
                    'su': button.getAttribute('data-sbg-subject'),
                    'subject': button.getAttribute('data-sbg-subject'),
                    'body': button.getAttribute('data-sbg-body')
                })
                button.setAttribute('href', url)
                break;

        }
    }

    function buildUrl(url, parameters) {
        var qs = "";
        for (var key in parameters) {
            var value = parameters[key];
            if (!value) {
                continue
            }
            value = value.toString().split('\"').join('"');
            qs += key + "=" + encodeURIComponent(value) + "&";
        }
        if (qs.length > 0) {
            qs = qs.substring(0, qs.length - 1); //chop off last "&"
            url = url + "?" + qs;
        }
        return url;
    }

}

function PopulateSharePanel() {
    var pageTitle = $(document).attr('title');
    var pageHref = window.location.href;
    var pageDescription = $('meta[name=description]').attr("content");
    var facebookDescription = "";
    var twitterDescription = "";
    var emailDescription = "";

    if (pageDescription === undefined) {
        facebookDescription = "";
        twitterDescription = "" + pageHref;
        emailDescription = "" + pageHref;
    } else {
        facebookDescription = "";
        twitterDescription = pageDescription + " " + pageHref;
        emailDescription = pageDescription + " " + pageHref;
    }

    var facebookShareButton = '<li><a class="sbg-button sbg-button-facebook" data-sbg-network="facebook" data-sbg-url="' + pageHref + '" data-sbg-title="' + pageTitle + '" data-sbg-summary="' + facebookDescription + '" data-sbg-image="" data-sbg-width="600" data-sbg-height="368"><span class="fa-stack fa-lg" id="ShareFacebook"><span class="fa fa-circle fa-stack-2x"></span><span class="fa fa-facebook fa-stack-1x"></span></span></a></li>';
    var twitterShareButton = '<li><a class="sbg-button sbg-button-twitter" data-sbg-network="twitter" data-sbg-text="' + twitterDescription + '" data-sbg-title="' + pageTitle + '" data-sbg-via="ForrestGeneral" data-sbg-hashtags="" data-sbg-width="600" data-sbg-height="258"><span class="fa-stack fa-lg" id="ShareTwitter"><span class="fa fa-circle fa-stack-2x"></span><span class="fa fa-twitter fa-stack-1x"></span></span></a></li>';
    var emailShareButton = '<li><a class="sbg-button sbg-button-email" data-sbg-network="email" data-sbg-subject="' + pageTitle + '" data-sbg-body="' + emailDescription + '"><span class="fa-stack fa-lg" id="ShareEmail"><span class="fa fa-circle fa-stack-2x"></span><span class="fa fa-envelope fa-stack-1x"></span></span></a></li>';

    $("#SharePanel ul").append(facebookShareButton).append(twitterShareButton).append(emailShareButton);
}
